const submenuItems = document.querySelectorAll('.topbar-item_submenu');

submenuItems.forEach((elem) => {
  const casesArrow = elem.querySelector('.submenu_block__arrow');
  const casesBlock = elem.querySelector('.submenu_block');

  let isOpenCases = false;

  const onClickCases = () => {
    isOpenCases = !isOpenCases;
    isOpenCases ? showCases() : hideCases();
  };

  const showCases = () => {
    casesBlock.style.display = 'block';
    casesArrow.style.transform = 'rotate(0.5turn)';
    elem.classList.add('topbar_submenu');
  };

  const hideCases = () => {
    casesBlock.style.display = 'none';
    casesArrow.style.transform = 'rotate(0turn)';
    elem.classList.remove('topbar_submenu');
  };

  elem.addEventListener('click', onClickCases);

  document.addEventListener('click', (e) => {
    if (e.composedPath().includes(elem)) {
      return;
    } else {
      const withinBoundaries = e.composedPath().includes(casesBlock);
      if (!withinBoundaries && isOpenCases) {
        hideCases();
        isOpenCases = false;
      }
    }
  });

  window.onresize = function () {
    if (document.body.clientWidth <= 853 && isOpenCases) {
      hideCases();
      isOpenCases = false;
    }
  };
});
